import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Timer from "./Timer"
import Optin from "./Optin";

const App = () => {
  return (
    <div className="App">
      <div className="container">
        <img src="/assets/logo.png" alt="logo" className="logo"/>
        <h1>
          ATHLETICS
          <br />
          Coming Soon
        </h1>
        {/* <Timer />
        <Optin /> */}
      </div>
    </div>
  );
};

export default App;
